import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsappSquare } from "@fortawesome/free-brands-svg-icons";

const defaultMsg = "Hola, quisiera más información acerca del inmueble cod.";

const WhatsappLink = ({
  phone,
  phoneDisplay,
  phone2,
  phone2Display,
  codPro,
}) => (
  <div className="d-flex flex-column">
    <FontAwesomeIcon
      icon={faWhatsappSquare}
      size="2x"
      fixedWidth
      color="#00e981"
      className="align-self-center"
    />
    <a
      href={`https://api.whatsapp.com/send?phone=${phone}&text=${defaultMsg} ${codPro}`}
      target="_blank"
    >
      <div style={{ fontSize: "11pt" }}>{phoneDisplay}</div>
    </a>
    {phone2 && (
      <a
        href={`https://api.whatsapp.com/send?phone=${phone2}&text=${defaultMsg} ${codPro}`}
        className=""
        target="_blank"
      >
        <div style={{ fontSize: "11pt" }}>{phone2Display}</div>
      </a>
    )}
  </div>
);

export default WhatsappLink;
