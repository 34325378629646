import React, { Component } from "react";
import qs from "query-string";
import { Link } from "gatsby";
import {
  Container,
  Row,
  Col,
  Alert,
  Badge,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneSquare,
  faMapMarkedAlt,
} from "@fortawesome/free-solid-svg-icons";
import PageLayout from "../components/PageLayout";
import Spinner from "../components/Spinner";
import WhatsappLink from "../components/WhatsappLink";
import EmailLink from "../components/EmailLink";
import { getProDetail } from "../services/v2/domus";

function formatNumber(number, locale = "en-US") {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "USD",
  }).format(number);
}

class InmueblePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listing: {},
      wasFound: true,
      isLoading: true,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    getProDetail(qs.parse(location.search).codpro)
      .then((response) => {
        const { data } = response.data;

        if (!data.length) {
          this.setState({ wasFound: false });
          return;
        }

        this.setState({
          listing: data[0],
          pictures: data[0].images,
        });
      })
      .then(() => {
        this.setState({ isLoading: false });
      });
  }

  render() {
    const { location } = this.props;

    const { listing, isLoading, wasFound, pictures } = this.state;

    const sliderSettings = {
      lazyLoad: "progressive",
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      adaptiveHeight: true,
    };

    return (
      <PageLayout>
        <Container>
          {isLoading && (
            <Row className="mt-5">
              <Col className="text-center">
                <Spinner />
              </Col>
            </Row>
          )}
          {!wasFound && !isLoading && (
            <Row className="mt-5">
              <Col>
                <Alert color="warning">
                  No se encontr&oacute; ninguna propiedad con el c&oacute;digo:
                  &nbsp;
                  {qs.parse(location.search).codpro}
                </Alert>
                <div className="text-right">
                  <Link to="/" className="btn btn-secondary">
                    Volver
                  </Link>
                </div>
              </Col>
            </Row>
          )}
          {wasFound && !isLoading && (
            <>
              <Row className="mt-3">
                <Col>
                  <ul
                    className="d-flex justify-content-end list-unstyled"
                    style={{ gap: "1em" }}
                  >
                    {listing.biz === "VENTA" ? (
                      <>
                        <li>
                          <WhatsappLink
                            phone={process.env.GATSBY_VENTAS_CELLPHONE}
                            phoneDisplay={process.env.GATSBY_VENTAS_CELLPHONE}
                            codPro={listing.codpro}
                          />
                        </li>
                        <li>
                          <EmailLink
                            email={process.env.GATSBY_VENTAS_EMAIL}
                            codpro={listing.codpro}
                          />
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href={`tel:${process.env.GATSBY_VENTAS_CELLPHONE}`}
                            className="d-inline-block ml-3 text-center"
                          >
                            <FontAwesomeIcon
                              icon={faPhoneSquare}
                              size="2x"
                              fixedWidth
                              color="#777"
                            />
                            <div style={{ fontSize: "11pt" }}>Pedir cita</div>
                          </a>
                        </li>
                      </>
                    ) : (
                      <>
                        {/* SECCION ARRENDAMIENTOS */}
                        <li>
                          <WhatsappLink
                            phone={process.env.GATSBY_ARRENDAMIENTOS_PHONE_1}
                            phoneDisplay="Linea 1"
                            phone2={process.env.GATSBY_ARRENDAMIENTOS_PHONE_2}
                            phone2Display="Linea 2"
                            codPro={listing.codpro}
                          />
                        </li>
                        <li>
                          <EmailLink
                            email={process.env.GATSBY_ARRIENDOS_EMAIL}
                            codpro={listing.codpro}
                          />
                        </li>
                        <li className="d-flex flex-column text-center">
                          <FontAwesomeIcon
                            icon={faPhoneSquare}
                            size="2x"
                            fixedWidth
                            color="#777"
                            className="align-self-center"
                          />
                          <div className="text-center">Pedir Cita</div>
                          <a
                            target="_blank"
                            href={`tel:${process.env.GATSBY_ARRENDAMIENTOS_PHONE_1}`}
                            className=""
                          >
                            <div style={{ fontSize: "11pt" }}>Linea 1</div>
                          </a>
                          <a
                            target="_blank"
                            href={`tel:${process.env.GATSBY_ARRENDAMIENTOS_PHONE_2}`}
                            className=""
                          >
                            <div style={{ fontSize: "11pt" }}>Linea 2</div>
                          </a>
                        </li>
                      </>
                    )}
                    <li className="text-center">
                      <a
                        href={`https://www.google.com/maps/search/${listing.latitude},${listing.longitude}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-center"
                      >
                        <FontAwesomeIcon
                          icon={faMapMarkedAlt}
                          size="2x"
                          fixedWidth
                          color="#999"
                        />
                        <div style={{ fontSize: "11pt" }}>Ubicaci&oacute;n</div>
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
              <Row className="mt-3 mt-lg-5">
                <Col sm="12" md="12" lg="8">
                  <Slider {...sliderSettings}>
                    {pictures.map((pic) => (
                      <div key={pic.thumburl}>
                        <img
                          src={pic.imageurl}
                          key={pic.thumburl}
                          width="100%"
                          alt={pic.thumburl}
                        />
                      </div>
                    ))}
                  </Slider>
                </Col>
                <Col sm="12" lg="4" className="mt-5 mt-lg-0">
                  <h4 className="text-center">{`${listing.type} EN ${listing.biz}`}</h4>
                  <h5 className="text-center">{`${listing.neighborhood}, ${listing.city}`}</h5>
                  <ListGroup className="mt-3">
                    <ListGroupItem className="d-flex w-100 justify-content-between align-items-center">
                      <span>C&oacute;digo propiedad:</span>
                      <h4>
                        <Badge color="warning">{listing.codpro}</Badge>
                      </h4>
                    </ListGroupItem>
                    <ListGroupItem className="d-flex w-100 justify-content-between bg-light">
                      <span>Valor:</span>
                      <h5 className="mb-0">
                        {"saleprice" in listing && listing.saleprice > 0 && (
                          <>
                            <div>{formatNumber(listing.saleprice)}</div>
                            <small>Venta</small>
                          </>
                        )}
                        {"saleprice" in listing &&
                          listing.saleprice > 0 &&
                          listing.rent > 0 &&
                          "rent" in listing && <hr />}
                        {"rent" in listing && listing.rent > 0 && (
                          <>
                            <div>{formatNumber(listing.rent)}</div>
                            <small>Arriendo</small>
                          </>
                        )}
                      </h5>
                    </ListGroupItem>
                    <ListGroupItem className="d-flex w-100 justify-content-between">
                      <span>Adm&oacute;n:</span>
                      <h5 className="mb-0">
                        {formatNumber(listing.administration)}
                      </h5>
                    </ListGroupItem>
                    <ListGroupItem className="d-flex w-100 justify-content-between bg-light">
                      <span>Estrato:</span>
                      <h5 className="mb-0">{listing.stratum}</h5>
                    </ListGroupItem>
                    <ListGroupItem className="d-flex w-100 justify-content-between">
                      <span>Area:</span>
                      <h5 className="mb-0">
                        {listing.area_cons} M<sup>2</sup>
                      </h5>
                    </ListGroupItem>
                    <ListGroupItem className="d-flex w-100 justify-content-between bg-light">
                      <span>Habitaciones:</span>
                      <h5 className="mb-0">{listing.bedrooms}</h5>
                    </ListGroupItem>
                    <ListGroupItem className="d-flex w-100 justify-content-between">
                      <span>Ba&ntilde;os:</span>
                      <h5 className="mb-0">{listing.bathrooms}</h5>
                    </ListGroupItem>
                    <ListGroupItem className="d-flex w-100 justify-content-between bg-light">
                      <span>Garajes:</span>
                      <h5 className="mb-0">{listing.parking}</h5>
                    </ListGroupItem>
                  </ListGroup>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col lg="8" className="text-justify">
                  <h4 className="text-muted">
                    Descripci&oacute;n del Inmueble
                  </h4>
                  <p>{listing.description}</p>
                </Col>
              </Row>
              {listing.amenities && (
                <Row className="mt-5">
                  <Col>
                    <h4 className="text-muted">Complementos del Inmueble</h4>
                    <Row className="mt-3">
                      {listing.amenities.map((item, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Col xs="12" sm="6" md="4" lg="3" key={item + index}>
                          <div>
                            <span style={{ color: "#BCA068" }} className="mr-2">
                              &#10095;
                            </span>
                            {item.name}
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
              )}
            </>
          )}
        </Container>
      </PageLayout>
    );
  }
}

export default InmueblePage;
