import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeSquare } from "@fortawesome/free-solid-svg-icons";

const EmailLink = ({ email, codpro }) => (
  <a
    href={`mailto:${email}?subject=Información acerca del inmueble cod. ${codpro}&body=Cordial%20saludo%2C%0A%0AAgradezco%20me%20contacten%20para%20obtener%20mayor%20informaci%C3%B3n%20acerca%20del%20inmueble%20publicado%20con%20c%C3%B3digo%3A%20${codpro}%0A%0AGracias.`}
    className="text-center d-inline-block ml-3"
  >
    <FontAwesomeIcon
      icon={faEnvelopeSquare}
      size="2x"
      fixedWidth
      color="#BCA068"
    />
    <div style={{ fontSize: "11pt" }}>Email</div>
  </a>
);

export default EmailLink;
